.picker__swatches {
    display: flex;
    /*padding: 12px;*/
    flex-wrap: wrap;
}

.picker__swatch {
    width: 15px;
    height: 15px;
    margin: 8px;
    border: none;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
}

.picker__swatch__selected {

    border-radius: 0;
    /*background: #FFFFFF url("../../images/rightIcon.svg");*/
    border: 1px solid #000000;
    /*box-sizing: border-box;*/
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

}
